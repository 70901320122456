<!--门禁模块--读卡器详情页--设备详细信息组件-->
<template>
  <div id="particulars">
    <div class="row-title">
      <span>{{ $t("details.a65") }}</span>
    </div>
    <!-- 时间表 -->
    <div class="row-content">
      <div>
        <span>{{ $t("details.a66") }}</span>
      </div>
      <div>
        <a-select v-model="terminalData.scheduleID" style="width: 150px">
          <a-select-option
            v-for="item in terminalData.schedules"
            :key="item.value"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <!-- 日历 -->
    <div class="row-content">
      <div>
        <span>{{ $t("details.a67") }}</span>
      </div>
      <div>
        <a-select v-model="terminalData.calanderID" style="width: 150px">
          <a-select-option
            v-for="item in terminalData.calanders"
            :key="item.value"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <!-- 数字键超时时间 -->
    <div class="row-content">
      <div>
        <span>{{ $t("details.a68") }}</span>
      </div>
      <div>
        <a-input v-model="terminalData.timeout" disabled />
      </div>
    </div>
    <!-- 数字键终端有无 -->
    <div class="row-content">
      <div>
        <span>{{ $t("details.a69") }}</span>
      </div>
      <div>
        <a-input v-model="terminalData.terminalExist" disabled/>
      </div>
    </div>
    <!-- 警戒温度下限 -->
    <div class="row-content" v-if="data.type == 208">
      <div>
        <span>{{ $t("details.a70") }}</span>
      </div>
      <div>
        <a-input v-model="terminalData.lowerLimit" />
      </div>
    </div>
    <!-- 警戒温度上限 -->
    <div class="row-content" v-if="data.type == 208">
      <div>
        <span>{{ $t("details.a71") }}</span>
      </div>
      <div>
        <a-input v-model="terminalData.upperLimit" />
      </div>
    </div>
    <!-- 警戒温度异常是否开门 -->
    <div class="row-content" v-if="data.type == 208">
      <div>
        <span>{{ $t("details.a72") }}</span>
      </div>
      <div>
        <a-radio-group v-model="terminalData.open" name="radioGroup">
          <a-radio value="1">{{ $t("details.a73") }}</a-radio>
          <a-radio value="2">{{ $t("details.a74") }}</a-radio>
        </a-radio-group>
      </div>
    </div>
    <!-- 认证模式 -->
    <div class="row-content">
      <div>
        <span>{{ $t("details.a75") }}</span>
      </div>
      <div>
        <a-select v-model="terminalData.authenticationMode" style="width: 150px">
          <a-select-option
            v-for="item in terminalData.authenticationModes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="row-footer">
      <a-button type="primary" style="width: 120px" ghost @click="setDetail" :loading="loading">{{ $t("details.a12") }}</a-button>
    </div>
  </div>
</template>

<script>
import { setReaderDetail } from '../../../api/door'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      checked: false,
      disabled: true,
      terminalData:{},
      loading:false
    };
  },
  watch:{
    data:{
      handler(newValue){
        const _data = JSON.stringify(newValue.readerDetail)
        const data = JSON.parse(_data)
        this.terminalData = data
      }
    }
  },
  methods: {
    // 读卡器设备详情信息设置方法
    setDetail() {
      this.$confirm({
        title: this.$t("details.a30"),
        centered: true,
        onOk: () => {
          this.loading = true
          // console.log(this.terminalData)
          const authenticationMode = this.terminalData.authenticationMode
          const calanderID = this.terminalData.calanderID
          const id = this.data.id
          const type = this.data.type
          const lowerLimit = this.terminalData.lowerLimit
          const open = this.terminalData.open
          const scheduleID = this.terminalData.scheduleID
          const terminalExist = this.terminalData.terminalExist
          const timeout = this.terminalData.timeout
          const upperLimit = this.terminalData.upperLimit
          const data = { authenticationMode,calanderID,id,type,lowerLimit,open,scheduleID,terminalExist,timeout,upperLimit}
          // console.log('data',data)
          setReaderDetail(data)
          .then(res=>{
            // console.log(res)
            this.loading = false
            const { errorCode,msg } = res
            if(errorCode == '00'){
              this.$message.success(msg)
            }else{
              this.$message.error(msg)
            }
          })
          .catch(err=>{
            console.log(err)
            this.loading = false
          })
        }
      })
    },
  },
};
</script>

<style scoped>
#particulars {
  width: 100%;
  border-radius: 3px;
}
.row-title {
  font-size: 18px;
  background-color: #f2f4f7;
  padding: 10px 20px;
  border: 1px solid #e8e9ea;
}
.row-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid #e8e9ea;
  border-top: none;
}
.row-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid #e8e9ea;
  border-top: none;
}
input {
  width: 150px;
  /* margin-right: 10px; */
}
</style>