<!--门禁模块--读卡器详情页--操作状态/故障·报警组件-->
<template>
  <div id="detailstabel">
    <table>
      <tr class="tabletitle">
        <td>{{ $t("details.a13") }}</td>
        <td>{{ $t("details.a14") }}</td>
        <td>{{ $t("details.a15") }}</td>
        <td>{{ $t("details.a13") }}</td>
        <td>{{ $t("details.a14") }}</td>
        <td>{{ $t("details.a15") }}</td>
      </tr>
      <tr class="tablecontent top">
        <td rowspan="3">{{ $t("details.a76") }}</td>
        <td rowspan="3">
          <img src="../../../../public/images/icon/527.png" alt />
          <img src="../../../../public/images/icon/528.png" alt />
          <img src="../../../../public/images/icon/529.png" alt />
        </td>
        <td>{{ data.runStatus.operate.changeDatetime }}</td>
        <td rowspan="2" class="nihao">{{ $t("details.a77") }}</td>
        <td rowspan="2">
          <img src="../../../../public/images/icon/527.png" alt />
          <img src="../../../../public/images/icon/528.png" alt />
        </td>
        <!-- <td>{{ data.illegalCard.operate.changeDatetime }}</td> -->
      </tr>
      <tr class="tablecontent">
        <td>{{ data.runStatus.status.changeDatetime }}</td>
        <!-- <td>{{ data.illegalCard.status.changeDatetime }}</td> -->
      </tr>
      <tr class="tablecontent">
        <td>{{ data.runStatus.alarm.changeDatetime }}</td>
        <td class="nihao"></td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        runStatus: {
          alarm: {},
          operate: {},
          status: {},
        },
        illegalCard: {
          alarm: {},
          operate: {},
        },
      }),
    },
  },
};
</script>
<style scoped>
#detailstabel {
  width: 100%;
  margin-bottom: 40px;
}
table {
  width: 100%;
  border-bottom: 1px solid #dedfdf;
}
.tabletitle td {
  color: #3e3a39;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
}
.tablecontent td {
  padding: 10px 16px;
  font-size: 14px;
}
.top {
  border-top: 1px solid #dedfdf;
}
.table-button {
  width: 120px;
}
img {
  width: 36px;
  margin-right: 15px;
}
.nihao {
  border-left: 1px solid #dedfdf;
}
</style>