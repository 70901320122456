<!--门禁模块--读卡器详情页-->
<template>
  <div id="cardReaderDetails" v-loading="spinning" :element-loading-text="$t('doorDetails.a1')">
    <div class="header">
      <span>{{ data.name }}</span>
      <a-icon type="close" :style="{ fontSize: '20px', color: '#7682CE' }" @click="goDack"/>
    </div>
    <div class="main">
      <!-- 基本信息 -->
      <my-info :data="data.baseInfo" @updateName="updateName" style="margin-bottom:20px"/>
      <!-- 操作状态/故障·报警标题 -->
      <my-title :title="title" />
      <!-- 读卡器操作状态/故障·报警表格 -->
      <my-table :data="data.readerItemInfo"/>
      <div class="main-layout">
        <div class="main-layout-left">
          <!-- 设备详情信息 -->
          <my-devicedetail :data="data"/>
        </div>
        <div class="main-layout-right">
          <!-- 时间表恢复 -->
          <my-timetable :data="data.scheduleRecover" :type="data.type" :id="data.id"/>
        </div>
      </div>
      <!-- 时间表手动恢复 -->
      <my-recovery :type="data.type" :id="data.id" />
      <!-- 当次日时间表标题 -->
      <my-templatetitle :name="data.name" />
      <!-- 当次日时间表 -->
     <my-dailySchedule :data="data"/>
    </div>
  </div>
</template>

<script>
import info from './components/baseInfo'
import tabletitle from '../../components/Title/tabletitle'
import terminalTable from './components/terminalTable'
import templateTitle from './components/templateTitle'
import terminalDeviceDetail from './components/terminalDeviceDetail'
import timetableResumption from './components/timetableResumption'
import manualRecovery from './components/manualRecovery'
import dailySchedule from './components/dailySchedule.vue'
import { getCardReaderDetails } from '../../api/door'
import moment from 'moment'

export default {
  name:'cardReaderDetails',
  components: {
    'my-info': info,
    'my-title': tabletitle,
    'my-table': terminalTable,
    'my-templatetitle': templateTitle,
    'my-devicedetail': terminalDeviceDetail,
    'my-timetable': timetableResumption,
    'my-recovery': manualRecovery,
    'my-dailySchedule':dailySchedule
  },
  data() {
    return {
      spinning: false,
      title: this.$t('doorDetails.a101'),
      data: {}
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    moment,
    // 页面回退方法
    goDack() {
      this.$router.go(-1);
    },
    // 获取读卡器详情页面数据的方法
    getData() {
      this.spinning = true
      let readerId = this.$route.query.id
      getCardReaderDetails({ readerId })
        .then((res) => {
          this.spinning = false
          let { data } = res
          this.data = data
          for (var i in data.scheduleRecover) { 
            if (typeof data.scheduleRecover[i] == "string") { data.scheduleRecover[i] = moment(data.scheduleRecover[i], "HH:mm") }
          }
        })
        .catch((err) => {
          this.spinning = false
          console.log("读卡器数据获取失败")
        })
    },
    // 基本信息名称修改成功后同时修改父组件中的名称
    updateName(data) {
      this.data.baseInfo.name = data
      this.data.name = data
    }
  }
}
</script>

<style scoped>
#cardReaderDetails {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px #bdbcbc;
  border-radius: 3px;
  border-radius: 3px;
  background: #ffffff;
  overflow: hidden;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 20px 20px 20px 20px;
  border-bottom: 1px solid #cccdcd;
  white-space: nowrap;
  overflow: hidden;
}
.main {
  width: 100%;
  height: calc(100% - 68px);
  padding: 20px 20px 20px 20px;
  overflow: auto;
}
.main-layout {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.main-layout-left {
  width: 49%;
}
.main-layout-right {
  width: 49%;
}
</style>